<template>
  <div class="data-source-page">
    <b-modal
      id="modal-add-sql-server"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.createConection')"
    >
      <add-data-source-sql-server
        @show-message="closeModalAddSQL"
        @refetch-data="getConnections"
        @changeModal="(value) => openModal(value)"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-add-excel"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.createConection')"
    >
      <add-data-source-excel
        @show-message="closeModalAddExcel"
        @refetch-data="getConnections"
        @changeModal="(value) => openModal(value)"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-add-sap"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.createConection')"
    >
      <add-data-source-sap
        @show-message="closeModalAddSap"
        @refetch-data="getConnections"
        @changeModal="(value) => openModal(value)"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-add-api-restful"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.createConection')"
    >
      <add-data-source-api-restful
        @show-message="closeModalAddAPI"
        @refetch-data="getConnections"
        @changeModal="(value) => openModal(value)"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-add-data-factory"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.createConection')"
    >
      <add-data-source-azure-data-factory
        :connectionId="connectionId"
        @show-message="closeModalAddDataFactory"
        @refetch-data="getConnections"
        @changeModal="(value) => openModal(value)"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-edit-sql-server"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.editConection')"
    >
      <edit-data-source-sql-server
        :connectionId="connectionId"
        @show-message="closeModalEditSQL"
        @refetch-data="getConnections"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-edit-sap"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.editConection')"
    >
      <edit-data-source-sap
        :connectionId="connectionId"
        @show-message="closeModalEditSap"
        @refetch-data="getConnections"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-edit-excel"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.editConection')"
    >
      <edit-data-source-excel
        :connectionId.sync="connectionId"
        @show-message="closeModalEditExcel"
        @refetch-data="getConnections"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-edit-api-restful"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.editConection')"
    >
      <edit-data-source-api-restful
        :connectionId="connectionId"
        @show-message="closeModalEditAPI"
        @refetch-data="getConnections"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-modal
      id="modal-edit-data-factory"
      shadow
      bg-variant="white"
      backdrop
      hide-footer
      centered
      :title="$t('IntegrationPage.editConection')"
    >
      <edit-data-source-azure-data-factory
        :connectionId="connectionId"
        @show-message="closeModalEditDataFactory"
        @refetch-data="getConnections"
        :listagemContexto="rows"
      />
    </b-modal>

    <b-card id="dts__card">
      <div class="header-card">
        <span class="title">{{ $t('IntegrationPage.DataSource') }}</span>

        <div class="d-flex align-items-center justify-content-end col-buttons">
          <b-form-group>
            <div class="d-flex align-items-center container-buttons">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="getConnections()"
                class="btn-update-all d-flex justify-content-center reload"
              >
                <ReloadIcon />
                <span class="align-middle">{{ $t('IntegrationPage.Refresh') }}</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="updateStatus(0)"
                class="btn-update-all d-flex justify-content-center"
                v-if="!testing"
              >
                <feather-icon icon="CheckCircleIcon" class="mr-50" />
                <span class="align-middle">{{ $t('IntegrationPage.TestAll') }}</span>
              </b-button>
              <b-button
                v-if="testing"
                class="btn-update-all d-flex justify-content-center"
                disabled
              >
                <b-spinner small style="margin-right: 15px" />
                {{ $t('IntegrationPage.Testing') }} ...
              </b-button>
              <b-dropdown
                id="dropdown-1"
                variant="none"
                :text="$t('IntegrationPage.NewConnection')"
                class="dropdown-connection"
              >
                <b-dropdown-item v-b-modal.modal-add-api-restful>REST</b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-add-data-factory
                  >Azure Data Factory</b-dropdown-item
                >
                <b-dropdown-item v-b-modal.modal-add-excel>EXCEL (.csv)</b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-add-sap>SAP (RFC)</b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-add-sql-server>SQL Server</b-dropdown-item>
              </b-dropdown>
            </div>
          </b-form-group>
        </div>
      </div>

      <div class="container-table-integration">
        <table-default
          :rows="rows"
          :fields="fields"
          :stickyHeader="false"
          :striped="true"
          :sizeSearch="12"
          class="table-integration"
          :class="rows.length > 0 ? 'table-void' : ''"
          @update-displayed-rows="(v) => (displayedRows = v)"
        >
          <template v-slot:cell(status)="data">
            <b-spinner
              label="Loading..."
              variant="primary"
              v-if="data.item.status == 'Connecting...'"
            />
            <b-badge v-else :variant="statusVariant(data.item.status)">
              {{ $t(`IntegrationPage.${data.item.status}`) }}
            </b-badge>
          </template>

          <template v-slot:cell(action)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="col-action"
                id="d-action-data-source"
                :boundary="displayedRows > 3 ? 'scrollParent' : 'window'"
                dropright
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="updateStatus(data.item.id)"
                  :disabled="
                    data.item.type_id == 1 || data.item.status == 'Connecting...' ? true : false
                  "
                >
                  <ImgTestIcon />
                  <span>{{ $t('IntegrationPage.TestConnection') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="setObjects(data.item.id, data.item.type_id)"
                  :disabled="data.item.status == 'Connected' ? false : true"
                >
                  <ImgAddIcon />
                  <span>{{ $t('IntegrationPage.DataSet') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="setConnectionId(data.item.id, data.item.type)"
                  :disabled="
                    data.item.type_id == 1 || data.item.status == 'Connecting...' ? true : false
                  "
                >
                  <ImgEditIcon />
                  <span>{{ $t('IntegrationPage.Edit') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="deleteConnection(data.item.id)"
                  :disabled="data.item.type_id == 1 ? true : false"
                >
                  <ImgDeleteIcon />
                  <span>{{ $t('IntegrationPage.delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>

          <template v-slot:cell(name)="data">
            <div class="add-ellipsis">
              <span :id="`tooltip-connection-name-${data.index}`">
                {{ data.item.name }}
              </span>
            </div>
            <b-tooltip
              custom-class="tooltip-data-source"
              :target="`tooltip-connection-name-${data.index}`"
              triggers="hover blur"
              positioning="top"
              placement="top"
              boundary-padding="0"
            >
              <div class="info-tooltip-data-source">
                {{ data.item.name }}
              </div>
            </b-tooltip>
          </template>
        </table-default>

        <div class="demo-spacing-0">
          <b-alert
            variant="primary"
            :show="flagShowOverlay == false && rows.length == 0 ? true : false"
          >
            <div class="alert-body">
              <span>Nenhum resultado encontrado.</span>
            </div>
          </b-alert>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
  import {
    BRow,
    BTooltip,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    BSidebar,
    VBToggle,
    BButton,
    BSpinner,
    BModal,
    BCardText
  } from 'bootstrap-vue';
  import { VueGoodTable } from 'vue-good-table';
  import store from '@/store/index';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import AddDataSourceSqlServer from './AddDataSourceSqlServer.vue';
  import AddDataSourceSap from './AddDataSourceSap.vue';
  import AddDataSourceExcel from './AddDataSourceExcel.vue';
  import AddDataSourceApiRestful from './AddDataSourceApiRestful.vue';
  import AddDataSourceAzureDataFactory from './AddDataSourceAzureDataFactory.vue';
  import EditDataSourceSqlServer from './EditDataSourceSqlServer.vue';
  import EditDataSourceSap from './EditDataSourceSap.vue';
  import EditDataSourceExcel from './EditDataSourceExcel.vue';
  import EditDataSourceApiRestful from './EditDataSourceApiRestful.vue';
  import EditDataSourceAzureDataFactory from './EditDataSourceAzureDataFactory.vue';
  import TableDefault from '@/@core/components/table-default/TableDefault.vue';
  import ImgDeleteIcon from '@/assets/images/pages/delete.svg';
  import ImgAddIcon from '@/assets/images/pages/add-circle.svg';
  import ImgEditIcon from '@/assets/images/pages/edit2.svg';
  import ImgTestIcon from '@/assets/images/pages/check-circle.svg';
  import ReloadIcon from '@/assets/images/pages/Reload.svg';
  import { mapMutations, mapGetters } from 'vuex';

  export default {
    components: {
      BRow,
      VueGoodTable,
      BTooltip,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BAlert,
      BDropdown,
      BDropdownItem,
      BCard,
      ToastificationContent,
      BSidebar,
      BModal,
      BButton,
      BSpinner,
      BCardText,
      AddDataSourceSqlServer,
      AddDataSourceSap,
      AddDataSourceExcel,
      AddDataSourceApiRestful,
      EditDataSourceSqlServer,
      EditDataSourceSap,
      EditDataSourceExcel,
      EditDataSourceApiRestful,
      TableDefault,
      ImgAddIcon,
      ImgDeleteIcon,
      ImgEditIcon,
      ImgTestIcon,
      AddDataSourceAzureDataFactory,
      EditDataSourceAzureDataFactory,
      ReloadIcon
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        pageLength: 10,
        dir: false,
        connectionId: '',
        fields: [
          {
            label: 'IntegrationPage.Actions',
            key: 'action',
            sortable: false
          },
          {
            label: 'ID',
            key: 'id',
            sortable: true
          },
          {
            label: 'STATUS',
            key: 'status',
            sortable: true
          },
          {
            label: 'IntegrationPage.ConnectionNameColumn',
            key: 'name',
            sortable: true,
            class: 'col-name-type'
          },
          {
            label: 'IntegrationPage.Types',
            key: 'type',
            sortable: true,
            class: 'col-name-type'
          }
        ],
        rows: [],
        searchTerm: '',
        refreshing: false,
        testing: false,
        intervalFunction: 0,
        displayedRows: 0
      };
    },
    created() {
      this.getConnections();
      this.atualizaDados();
    },
    destroyed() {
      clearInterval(this.intervalFunction);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      atualizaDados() {
        const timeRefreshSeconds = 15000;

        this.intervalFunction = setInterval(() => this.getConnections(), timeRefreshSeconds);
      },
      setConnectionId(id, type) {
        this.connectionId = id;

        switch (type) {
          case 'SQL Server':
            this.$bvModal.show('modal-edit-sql-server');
            break;
          case 'SAP':
            this.$bvModal.show('modal-edit-sap');
            break;
          case 'CSV':
            this.$bvModal.show('modal-edit-excel');
            break;
          case 'REST':
            this.$bvModal.show('modal-edit-api-restful');
            break;
          case 'Data Factory':
            this.$bvModal.show('modal-edit-data-factory');
            break;
        }
      },
      openModal(value) {
        this.$bvModal.hide('modal-add-api-restful');
        this.$bvModal.hide('modal-add-sql-server');
        this.$bvModal.hide('modal-add-sap');
        this.$bvModal.hide('modal-add-excel');
        this.$bvModal.hide('modal-add-data-factory');

        if (value == 1) {
          this.$bvModal.show('modal-add-api-restful');
        } else if (value == 2) {
          this.$bvModal.show('modal-add-sql-server');
        } else if (value == 3) {
          this.$bvModal.show('modal-add-sap');
        } else if (value == 4) {
          this.$bvModal.show('modal-add-excel');
        } else if (value == 5) {
          this.$bvModal.show('modal-add-data-factory');
        }
      },
      setObjects(id, type) {
        this.$router.push({
          name: 'object',
          params: { prefix: this.$router.currentRoute.params.prefix, connection_id: id, type: type }
        });
      },
      getConnections() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/connection?site_guid=${this.currentSite.guid}`
          )
          .then((res) => {
            this.rows = res.data;
            this.refreshing = false;
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      refreshConnections() {
        this.refreshing = true;
        this.getConnections();
      },
      updateStatus(id) {
        this.testing = true;
        this.$http
          .put(
            `/api/${this.$router.currentRoute.params.prefix}/connection/${id}?site_guid=${this.currentSite.guid}`,
            { statusId: 2 }
          )
          .then((response) => {
            this.getConnections();
            this.testing = false;
          })
          .catch((error) => {
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
            this.testing = false;
          });
      },
      deleteConnection(id) {
        this.$swal({
          title: this.$t('IntegrationPage.attention'),
          text: this.$t('IntegrationPage.deleteConection'),
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: this.$t('IntegrationPage.cancel'),
          confirmButtonText: this.$t('IntegrationPage.delete'),
          customClass: {
            container: 'swal-default',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.$http
              .delete(
                `/api/${this.$router.currentRoute.params.prefix}/connection/${id}?site_guid=${this.currentSite.guid}`
              )
              .then(() => {
                this.getConnections();
                this.showToast(
                  this.$t('IntegrationPage.success'),
                  'SmileIcon',
                  this.$t('IntegrationPage.deleteConectionSuccess'),
                  'success'
                );
              })
              .catch((error) => {
                this.UPDATE_FLAG_SHOW_OVERLAY(false);
                this.showToast('Erro', 'FrownIcon', error.response.data.message, 'danger');
              });
          }
        });
      },
      closeModalAddSQL() {
        this.$bvModal.hide('modal-add-sql-server');
      },
      closeModalAddSap() {
        this.$bvModal.hide('modal-add-sap');
      },
      closeModalAddExcel() {
        this.$bvModal.hide('modal-add-excel');
      },
      closeModalAddAPI() {
        this.$bvModal.hide('modal-add-api-restful');
      },
      closeModalAddDataFactory() {
        this.$bvModal.hide('modal-add-data-factory');
      },
      closeModalEditSQL() {
        this.$bvModal.hide('modal-edit-sql-server');
      },
      closeModalEditSap() {
        this.$bvModal.hide('modal-edit-sap');
      },
      closeModalEditExcel() {
        this.$bvModal.hide('modal-edit-excel');
      },
      closeModalEditAPI() {
        this.$bvModal.hide('modal-edit-api-restful');
      },
      closeModalEditDataFactory() {
        this.$bvModal.hide('modal-edit-data-factory');
      },
      statusVariant(status) {
        let variant;
        switch (status) {
          case 'Connecting...':
            variant = 'warning';
            break;
          case 'Connected':
            variant = 'success';
            break;
          case 'Not connected':
            variant = 'danger';
            break;
        }
        return variant;
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      }
    },
    computed: {
      ...mapGetters('app', ['flagShowOverlay']),
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true;
          return this.dir;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false;
        return this.dir;
      }
    }
  };
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-sweetalert.scss';

  .dts__breadcrumb {
    margin-bottom: 24px;
  }

  .data-source-page {
    overflow-x: hidden;
    min-height: calc(100vh - 7rem);
  }

  .tooltip-data-source {
    //não adicione padding aqui e só estilize as classes especificas se possível
    margin: 0;
    .tooltip-inner {
      padding: 0;
      max-width: 100%;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;

      .info-tooltip-data-source {
        max-width: 467px;
        padding: 8px 8px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        letter-spacing: 0.16px;
      }
    }

    .arrow {
      &::before {
        border-top-color: #323232;
        border-bottom-color: #323232;
      }
    }
  }

  .overlay-mensage {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .swal2-close {
    color: #4c4541 !important;
    font-size: 2em;
  }

  .container-table-integration {
    table {
      .tooltip {
        .tooltip-inner {
          max-width: 363px !important;
          .info-tooltip {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .add-ellipsis {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      thead th {
        padding: 10.5px 9px !important;
      }

      th {
        padding: 11px 9px !important;
      }

      td {
        padding: 14.5px 10px !important;
        height: 38px !important;
        letter-spacing: 0;
        border: 1px solid #e0e0e0 !important;
        border-bottom: none !important;
        border-left: none !important;
        border-right: none !important;
      }

      th:nth-child(1) {
        padding: 10.5px 7.65px !important;
      }

      td:nth-child(1) {
        text-align: center;
      }

      th:nth-child(1),
      td:nth-child(1) {
        min-width: 57px !important;
      }

      th:nth-child(2),
      td:nth-child(2) {
        min-width: 52px !important;
        max-width: 52px !important;
      }

      th:nth-child(3),
      td:nth-child(3) {
        min-width: 132px !important;
        max-width: 132px !important;
      }

      th:nth-child(4),
      td:nth-child(4) {
        min-width: 462.5px;
        max-width: calc(50vw - ((52px + 57px + 132px) / 2) - (2 * 16.8px) + 7px) !important;
        width: calc(50vw - ((52px + 57px + 132px) / 2) - (2 * 16.8px) + 7px) !important;
      }

      th:nth-child(5),
      td:nth-child(5) {
        min-width: 462.5px;
        max-width: calc(50vw - ((52px + 57px + 132px) / 2) - (2 * 16.8px) + 7px) !important;
        width: calc(50vw - ((52px + 57px + 132px) / 2) - (2 * 16.8px) + 7px) !important;
      }

      #d-action-data-source {
        button {
          width: 32px;
          height: 28px;
          padding: 0;
          svg circle {
            color: #974900 !important;
          }
        }

        .dropdown-menu.show {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }

        .dropdown-item {
          padding: 12px 21px 12px 21px !important;
          display: flex;

          &:hover {
            background-color: #fbeee8;
            svg path {
              fill: #974900 !important;
            }
            span {
              color: #974900 !important;
            }
          }
          &.disabled {
            svg {
              path {
                fill: #cfc4be !important;
              }
            }
            .text-action-pipeline {
              color: #cfc4be !important;
            }
          }
          &:active {
            background-color: #ffdbc4;
            svg path {
              fill: #974900 !important;
            }
            span {
              color: #974900 !important;
            }
          }
          &:focus {
            background-color: #ffdbc4;
            svg path {
              fill: #974900 !important;
            }
            span {
              color: #974900 !important;
            }
          }
          span {
            margin-left: 9px;
          }
        }
        button:hover {
          background-color: #ffdbc4 !important;
        }
        svg {
          margin: 0 !important;
        }
      }
    }
  }

  @media (min-width: 768px) {
    #modal-add-sql-server,
    #modal-add-excel,
    #modal-add-sap,
    #modal-add-api-restful,
    #modal-edit-sql-server,
    #modal-edit-excel,
    #modal-edit-sap,
    #modal-edit-api-restful,
    #modal-add-data-factory,
    #modal-edit-data-factory {
      .modal-dialog {
        width: 746px !important;
        max-width: 746px !important;
      }
      .modal-content {
        width: 746px !important;
        max-width: 746px !important;
      }
    }
  }

  @media (max-width: 480px) {
    .header-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      .title {
        margin-bottom: 10px;
      }
      .container-buttons {
        flex-direction: column;
        align-items: flex-start;
      }
      .col-buttons {
        width: 100%;
        .form-group {
          width: 100%;
          .btn-update-all {
            width: 100%;
            margin: 0 0 10px !important;
            text-align: center;
          }
          .btn-group {
            width: 100%;
            .btn {
              width: 100% !important;
            }
          }
        }
      }
    }
    #modal-add-api-restful,
    #modal-edit-api-restful {
      .container-keys {
        margin-bottom: 10px !important;
        padding: 0 16px 16px !important;
        .container-key {
          border: 1px solid #cfc4be;
          border-radius: 6px;
          padding: 16px !important;
          .container-input-key,
          .container-input-value {
            padding: 0;
          }
          .add-row,
          .remove-row {
            padding: 0;
            margin-right: 0 !important;
          }
        }
        .remove-bearer {
          width: 100%;
          margin-top: 8px;
          .btn-primary,
          .btn-secondary {
            width: 100%;
          }
        }
      }
    }

    .table-integration {
      th:nth-child(4),
      td:nth-child(4),
      th:nth-child(5),
      td:nth-child(5) {
        min-width: 352px !important;
      }
    }
  }

  .header-card {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #4c4541;
    }

    .col-buttons {
      fieldset {
        margin-bottom: 0 !important;
      }
    }

    .container-buttons {
      gap: 16px;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #974900 !important;
    background-color: #974900 !important;
  }

  #dts__card > .header {
    background: #f8f8f8 !important;
    padding-top: 0;
    height: 50px;
  }

  .badge-success {
    color: #4caf50 !important;
    background: #e8f5e9 !important;
    border-radius: 4px;
  }

  .badge-danger {
    color: #f44336 !important;
    background: #ffebee !important;
    border-radius: 4px;
  }

  #modal-add-sql-server,
  #modal-add-excel,
  #modal-add-sap,
  #modal-add-api-restful,
  #modal-edit-sql-server,
  #modal-edit-excel,
  #modal-edit-sap,
  #modal-edit-api-restful,
  #modal-add-data-factory,
  #modal-edit-data-factory {
    .modal-dialog {
      width: 95%;
      max-width: none;
    }
    .modal-content {
      width: 100%;
      max-width: none;
    }
  }

  .modal-body {
    padding: 0;
    .modal-bottom {
      padding-bottom: 0 !important;
    }
  }

  .modal-content {
    .modal-header {
      padding: 20px 16px;
      .modal-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #4c4541;
      }
      .close {
        transform: none !important;
        transition: none !important;
        top: -16px;
        left: 5px;
      }
    }
    .add-row {
      margin-right: 8px;
      svg {
        position: absolute;
      }
      .btn {
        background: #974900 !important;
        border-radius: 5px;
        border: none;
        width: 10px;
        display: flex;
        justify-content: center;
        height: 36px;
      }
    }
    .remove-row {
      svg {
        position: absolute;
      }
      .btn {
        background: #d32f2f !important;
        border-radius: 5px;
        border: none;
        width: 10px;
        display: flex;
        justify-content: center;
        height: 36px;
      }
    }
    .save-button {
      .btn {
        background-color: #974900 !important;
        border-radius: 5px;
        border: none;
        font-size: 17px;
        width: 100px;
        height: 45px;
      }
    }
  }

  .col-action {
    .btn {
      padding: 0px;
    }
    .dropdown-menu {
      li {
        .dropdown-item {
          svg {
            margin-right: 9px;
          }
        }
        &:hover {
          background-color: #ffede2 !important;
          span {
            color: #974900 !important;
          }
        }
        &:focus {
          background-color: #ffdbc4 !important;
        }
      }
    }
  }

  .btn-update-all {
    padding: 7px 11px;
    display: flex;
    align-items: center;
    background-color: #ffffff !important;
    color: #974900 !important;
    border-radius: 6px;
    border: 1px solid #974900 !important;
    font-size: 12px;
    font-weight: 600;
    &:hover {
      background-color: #ffede1 !important;
      box-shadow: none !important;
    }
    &.reload {
      svg {
        margin-right: 7px;
      }
    }
    &:focus {
      background-color: transparent !important;
    }
  }

  .dropdown-connection {
    background: #974900 !important;
    border-radius: 5px;
    padding: 0;

    .btn {
      color: #ffffff !important;
      padding: 7px 15px;
      font-weight: 600;
      font-size: 12px;
    }

    &:hover {
      background: #9f5714 !important;
    }

    &:active,
    &:focus {
      background: #a45f1f !important;
    }

    .dropdown-item {
      &:hover {
        color: #974900;
        background: #ffede2;
      }

      &:active,
      &:focus {
        color: #974900;
        background: #ffdbc4;
      }
    }
  }

  .table-integration.table-void {
    table {
      min-height: 180px !important;
    }

    .not-found {
      margin-top: -128px;
    }
  }

  @media (min-width: 768px) {
    #modal-add-sql-server,
    #modal-add-excel,
    #modal-add-sap,
    #modal-add-api-restful,
    #modal-edit-sql-server,
    #modal-edit-excel,
    #modal-edit-sap,
    #modal-edit-api-restful,
    #modal-add-data-factory,
    #modal-edit-data-factory {
      .modal-dialog {
        width: 746px !important;
        max-width: 746px !important;
      }
      .modal-content {
        width: 746px !important;
        max-width: 746px !important;
      }
    }
  }

  @media (max-width: 600px) {
    .header-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      .title {
        margin-bottom: 10px;
      }
      .container-buttons {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
      .col-buttons {
        width: 100%;
        .form-group {
          width: 100%;
          .btn-update-all {
            width: 100%;
            text-align: center;
          }
          .btn-group {
            width: 100%;
            .btn {
              width: 100% !important;
            }
          }
        }
      }
    }
    #modal-add-api-restful,
    #modal-edit-api-restful {
      .container-keys {
        margin-bottom: 10px !important;
        padding: 0 16px 16px !important;
        .container-key {
          border: 1px solid #cfc4be;
          border-radius: 6px;
          padding: 16px !important;
          .container-input-key,
          .container-input-value {
            padding: 0;
          }
          .add-row,
          .remove-row {
            padding: 0;
            margin-right: 0 !important;
          }
        }
        .remove-bearer {
          width: 100%;
          margin-top: 8px;
          .btn-primary,
          .btn-secondary {
            width: 100%;
          }
        }
      }
    }

    #table-integration {
      th:nth-child(4),
      td:nth-child(4),
      th:nth-child(5),
      td:nth-child(5) {
        min-width: 352px !important;
      }
    }
  }
</style>
