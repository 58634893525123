<template>
  <div
    class="justify-content-between align-items-center content-sidebar px-1 py-1 modal-bottom modal-top"
  >
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="$t('IntegrationPage.Type')"
            label-for="type"
          >
            <b-form-select
              v-model="typeConnection"
              disabled
              id="authentication-type"
              :options="[
                { text: 'REST', value: 1 },
                { text: 'SQL Server', value: 2 },
                { text: 'SAP (RFC)', value: 3 },
                { text: 'Excel (.csv)', value: 4 }
              ]"
            >
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('IntegrationPage.NameConection')"
            label-for="name"
            :invalid-feedback="invalidNameState ? $t('NameInvalid') : $t('RequiredField')"
          >
            <b-form-input
              id="name"
              v-model="name"
              :placeholder="$t('IntegrationPage.ERP')"
              :state="stateName"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('IntegrationPage.Server')"
            label-for="datasource"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              placeholder="127.0.0.1"
              id="datasource"
              v-model="datasource"
              :state="stateDataSource"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            :label="$t('IntegrationPage.Database')"
            label-for="database"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              v-model="database"
              id="database"
              :placeholder="$t('TypeHere')"
              :state="stateDatabase"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            :label="$t('IntegrationPage.IntegratedSecurity')"
            label-for="integrated-security"
          >
            <b-form-checkbox
              switch
              :checked="true"
              @change="resetUserAndPasswordField()"
              v-model="integratedSecurity"
              id="integrated-security"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('IntegrationPage.Login')"
            label-for="user"
            v-if="!integratedSecurity"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-input
              v-model="user"
              id="user"
              :placeholder="$t('TypeHere')"
              :state="stateUser"
              autocomplete="off"
              onfocus="this.removeAttribute('readonly');"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('IntegrationPage.Password')"
            label-for="password"
            v-if="!integratedSecurity"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-input-group :class="{ 'is-invalid': statePassword === false }">
              <b-form-input
                class="border-right-0"
                :type="passwordType"
                v-model="password"
                id="password"
                placeholder="******"
                :state="statePassword"
                autocomplete="off"
                onfocus="this.removeAttribute('readonly');"
              />
              <b-input-group-append
                is-text
                style="cursor: pointer"
                @click="showPassword"
              >
                <eye v-if="passwordType === 'password'" />
                <eye-off v-else />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          md="12"
          class="d-flex justify-content-end save-button"
        >
          <b-form-group>
            <b-button
              v-if="!sendingForm"
              variant="primary"
              class="form-control"
              @click="createConnection()"
            >
              {{ $t('IntegrationPage.Save') }}
            </b-button>

            <b-button
              v-if="sendingForm"
              variant="primary"
              disabled
              class="form-control loading"
            >
              <b-spinner small />
              {{ $t('IntegrationPage.Sending') }} ...
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import flatPickr from 'vue-flatpickr-component';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { TheMask } from 'vue-the-mask';
  import Eye from '@/assets/images/icons/eye.svg';
  import EyeOff from '@/assets/images/icons/eye-off.svg';

  import {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BBadge,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend
  } from 'bootstrap-vue';

  export default {
    components: {
      BAvatar,
      BListGroup,
      BListGroupItem,
      BCardText,
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BFormSelectOption,
      BButton,
      BSpinner,
      BRow,
      BCol,
      BFormTextarea,
      BBadge,
      BFormCheckbox,
      TheMask,
      flatPickr,
      ToastificationContent,
      BInputGroup,
      BInputGroupAppend,
      Eye,
      EyeOff
    },
    directives: {
      Ripple
    },
    props: {
      connectionId: {
        type: String
      },
      listagemContexto: {
        type: Array
      }
    },
    mounted() {
      this.getConnectionById(this.connectionId);
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        type: 'SQL Server',
        name: '',
        datasource: '',
        database: '',
        user: '',
        password: '',
        integratedSecurity: 0,
        sendingForm: false,
        selected: null,
        typeConnection: 2,
        invalidNameState: null,
        stateName: null,
        stateDataSource: null,
        stateDatabase: null,
        stateUser: null,
        statePassword: null,
        contexto: [],
        passwordType: 'password'
      };
    },
    methods: {
      validaNomeFonte() {
        if (this.name != this.contexto.name) {
          let fonteExistente = this.listagemContexto.filter(
            (item) => item.name == this.name && item.type_id == '2'
          );
          if (fonteExistente.length > 0) {
            return true;
          }
          return false;
        }
      },
      validaCampos() {
        this.stateName = this.name && !this.validaNomeFonte() ? null : false;
        this.stateDataSource = this.datasource ? null : false;
        this.stateDatabase = this.database ? null : false;
        this.stateUser = null;
        this.statePassword = null;
        this.invalidNameState = this.validaNomeFonte();
        if (!this.integratedSecurity) {
          this.stateUser = this.user ? null : false;
          this.statePassword = this.password ? null : false;
        }
      },
      getConnectionById(id) {
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/connection/${id}?site_guid=${this.currentSite.guid}`
          )
          .then((response) => {
            this.contexto = response.data;
            this.integratedSecurity = response.data.integratedSecurity == 'False' ? false : true;
            this.name = response.data.name;
            this.datasource = response.data.datasource;
            this.database = response.data.database;
            this.user = response.data.user_sql_server;
            this.password = response.data.password_sql_server;
          })
          .catch((error) =>
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
          );
      },
      createConnection() {
        this.validaCampos();
        if (
          !this.integratedSecurity
            ? this.stateUser != false &&
              this.statePassword != false &&
              this.stateName != false &&
              this.stateDataSource != false &&
              this.stateDatabase != false
            : this.stateName != false &&
              this.stateDataSource != false &&
              this.stateDatabase != false
        ) {
          this.sendingForm = true;
          this.$http
            .post(`/api/${this.$router.currentRoute.params.prefix}/connection`, {
              id: this.connectionId,
              type: this.type,
              name: this.name,
              datasource: this.datasource,
              database: this.database,
              user: this.user,
              password: this.password,
              integratedSecurity: this.integratedSecurity,
              site_guid: this.currentSite.guid
            })
            .then((response) => {
              this.sendingForm = false;
              this.$emit('refetch-data');
              this.$emit('show-message');
            })
            .catch((error) => {
              this.sendingForm = false;
              this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
            });
        }
      },
      resetUserAndPasswordField() {
        if (this.integratedSecurity == 'True') {
          this.user = '';
          this.password = '';
        }
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      showPassword() {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      }
    }
  };
</script>
<style lang="scss">
  .btn.loading {
    width: auto !important;
  }
</style>
