import { render, staticRenderFns } from "./EditDataSourceApiRestful.vue?vue&type=template&id=c738c6fe&scoped=true&"
import script from "./EditDataSourceApiRestful.vue?vue&type=script&lang=js&"
export * from "./EditDataSourceApiRestful.vue?vue&type=script&lang=js&"
import style0 from "./EditDataSourceApiRestful.vue?vue&type=style&index=0&id=c738c6fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c738c6fe",
  null
  
)

export default component.exports